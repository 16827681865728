import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CiReceipt } from "react-icons/ci";
import { TbReceiptRupee } from "react-icons/tb";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCookies } from "react-cookie";

const CreateBill = () => {
  const toast = useToast();
  const [billNo, setBillNo] = useState();
  const [billedToNameAddress, setBilledToNameAddress] = useState("");
  const [description, setDescription] = useState("");
  const [findChallanNo, setFindChallanNo] = useState("");
  const [challanDate, setChallanDate] = useState();
  // const [biltyDate, setBiltyDate] = useState();
  const [challanNo, setChallanNo] = useState("");
  const [biltyNo, setBiltyNo] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [lorryNo, setLorryNo] = useState("");
  const [totalPackages, setTotalPackages] = useState();
  const [weight, setWeight] = useState();
  const [freight, setFright] = useState();
  const [totalFreight, setTotalFright] = useState();
  const [advancePaid, setAdvancePaid] = useState();
  // const [balancePaid, setBalancePaid] = useState();
  const [toBePaidAt, setToBePaidAt] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverPhone, setDriverPhone] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");
  const [engineNo, setEngineNo] = useState("");
  const [chessisNo, setChessisNo] = useState("");
  const [panCardNo, setPanCardNo] = useState("");
  const [agentBrokerNameAndAddress, setAgentBrokerNameAndAddress] =
    useState("");
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [contents, setContents] = useState("");
  const [destination, setDestination] = useState("");
  // const [bookingStation, setBookingStation] = useState("");
  const [licenseNo, setLicenseNo] = useState();

  const [priceType, setPriceType] = useState("Fixed");
  const [priceTotal, setPriceTotal] = useState();
  const [gstNo, setGstNo] = useState();
  const [stCh, setStCh] = useState();
  const [delCh, setDelCh] = useState();

  const [creatingBill, setCreatingBill] = useState(false);
  const [findingChallan, setFindingChallan] = useState(false);

  const navigate = useNavigate();
  const [cookies] = useCookies();

  const handleMaxChars = (maxChars, field, value, setFunc) => {
    if (value.length > maxChars) {
      toast({
        status: "error",
        description: `${field} field can contain max ${maxChars} characters`,
        position: "top",
      });
      return;
    }
    setFunc(value);
  };

  const fetchChallanDetails = async (e) => {
    e.preventDefault();

    try {
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(
        baseUrl + `challan/get?challan_no=${findChallanNo}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies?.token}`,
          },
        }
      );
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      setChallanDate((new Date(data?.challan.date)?.toISOString()?.substr(0, 10)));
      setChallanNo(findChallanNo);
      fetchBiltyDetails(data.challan.bilty_no);
      setBiltyNo(data.challan.bilty_no);
      // setBookingStation(data.challan.booking_station);
      setContents(data.challan.contents);
      setFrom(data.challan.from);
      setTo(data.challan.to);
      setLorryNo(data.challan.lorry_no);
      setWeight(data.challan.weight);
      setDriverName(data.challan?.driver_name);
      setDriverPhone(data.challan?.driver_phone);
      setLicenseNo(data.challan?.license_no);
      // setAdvancePaid(data.challan?.advance_paid);
      // setBalancePaid(data.challan?.balance_paid);
    } catch (error) {
      toast({
        status: "error",
        description: error.message,
        position: "top",
      });
    }
  };

  const fetchBiltyDetails = async (biltyNo) => {
    try {
      setFindingChallan(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + `bilty/get?bilty_no=${biltyNo}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies?.token}`,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      // setBiltyDate((new Date(data?.date)?.toISOString()?.substr(0, 10)));
      // setBilledToName(data.consignee_name);
      // setBilledToAddress(data.consignee_address);
      setDescription(data?.description);
    } catch (error) {
      toast({
        status: "error",
        description: error.message,
        position: "top",
      });
    } finally {
      setFindingChallan(false);
    }
  };

  const createBill = async (e) => {
    e.preventDefault();

    const body = {
      bill_no: billNo,
      bilty_no: biltyNo,
      challan_no: challanNo,
      consignee_name_address: billedToNameAddress,
      date: challanDate,
      // bilty_date: biltyDate,
      bilty_no: biltyNo,
      challan_no: challanNo,
      truck_no: lorryNo,
      weight: weight,
      // balance: balancePaid,
      advance: advancePaid,
      price_type: priceType,
      gst_no: gstNo,
      from: from,
      to: to,
      description: description,
      st_ch: stCh,
      del_ch: delCh,
      price_type: priceType,
      total: priceTotal,
      st_ch: stCh,
      del_ch: delCh,
    };

    console.log(body)

    try {
      setCreatingBill(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + "bill/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies?.token}`,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }

      toast({
        status: "success",
        position: "top",
        description: data.message,
      });

      navigate("/dashboard/all-bill");
    } catch (err) {
      toast({
        position: "top",
        status: "error",
        description: err.message,
      });
    } finally {
      setCreatingBill(false);
    }
  };

  return (
    <div className="py-5 px-5 h-[inherit] overflow-auto">
      <h1 className="text-2xl md:text-4xl mt-5 mb-6 font-bold flex gap-2">
        <IoMdArrowRoundBack onClick={() => navigate("/dashboard/all-bill")} />
        Create Bill
      </h1>

      {/* <div>
        <form onSubmit={fetchChallanDetails}>
          <FormControl isRequired={true}>
            <FormLabel>Challan No.</FormLabel>
            <Input
              required
              type="text"
              value={findChallanNo}
              onChange={(e) => setFindChallanNo(e.target.value)}
            />
          </FormControl>
          <div className="mt-5 mb-3">
            <Button
              isLoading={findingChallan}
              type="submit"
              width={"100%"}
              rightIcon={<TbReceiptRupee size={25} />}
              colorScheme="blue"
            >
              Find Challan
            </Button>
          </div>
        </form>
      </div> */}

        <div className="mt-8">
          <form onSubmit={createBill}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <FormControl isRequired={true}>
                <FormLabel>Bill No.</FormLabel>
                <Input type="text" value={billNo} onChange={(e)=>setBillNo(e.target.value)} />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Challan No.</FormLabel>
                <Input type="text" value={challanNo} onChange={(e)=>setChallanNo(e.target.value)} />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Challan Date</FormLabel>
                <Input type="date" value={challanDate} onChange={(e)=>setChallanDate(e.target.value)} />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Bilty No.</FormLabel>
                <Input type="number" value={biltyNo} onChange={(e)=>setBiltyNo(e.target.value)} />
              </FormControl>
              {/* <FormControl isRequired={true}>
                <FormLabel>Bilty Date</FormLabel>
                <Input type="date" value={biltyDate} onChange={(e)=>setBiltyDate(e.target.value)} />
              </FormControl> */}
              <FormControl isRequired={true}>
                <FormLabel>From</FormLabel>
                <Input type="text" value={from} onChange={(e)=>{
                  handleMaxChars(18, "From", e.target.value, setFrom);
                }} />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>To</FormLabel>
                <Input type="text" value={to} onChange={(e)=>{
                  handleMaxChars(18, "To", e.target.value, setTo);
                }} />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Lorry No.</FormLabel>
                <Input
                 
                  type="text"
                  value={lorryNo}
                  onChange={(e) =>
                    handleMaxChars(10, "Lorry No.", e.target.value, setLorryNo)
                  }
                />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Description</FormLabel>
                <Textarea
                  value={description}
                  onChange={(e) =>
                    handleMaxChars(100, "Description", e.target.value, setDescription)
                  }
                />
              </FormControl>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <FormControl>
                <FormLabel>Date</FormLabel>
                <Input
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                  // min={new Date().toISOString().substring(0, 10)}
                />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Contents</FormLabel>
                <Input
                 
                  type="text"
                  value={contents}
                  onChange={(e) =>
                    handleMaxChars(20, "Contents", e.target.value, setContents)
                  }
                />
              </FormControl>
              {/* <FormControl isRequired={true}>
                <FormLabel>Booking Station</FormLabel>
                <Input
                 
                  type="text"
                  value={bookingStation}
                  onChange={(e) =>
                    handleMaxChars(
                      18,
                      "Booking Station",
                      e.target.value,
                      setBookingStation
                    )
                  }
                />
              </FormControl> */}
              {/* <FormControl>
                <FormLabel>Total Packages</FormLabel>
                <Input
                  type="number"
                  value={totalPackages}
                  onChange={(e) =>
                    handleMaxChars(
                      10,
                      "Total Packages",
                      e.target.value,
                      setTotalPackages
                    )
                  }
                /> */}
              {/* </FormControl> */}
              <FormControl isRequired={true}>
                <FormLabel>Weight (In Tonne)</FormLabel>
                <Input
                 
                  type="text"
                  value={weight}
                  onChange={(e) =>
                    handleMaxChars(10, "Weight", e.target.value, setWeight)
                  }
                />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Billed To</FormLabel>
                <Textarea                 
                  type="text"
                  value={billedToNameAddress}
                  onChange={(e) =>
                    handleMaxChars(100, "Billed To", e.target.value, setBilledToNameAddress)
                  }
                />
              </FormControl>
            </div>

            {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <FormControl isRequired={true}>
                <FormLabel>Driver's Name</FormLabel>
                <Input type="text" value={driverName} />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>License No.</FormLabel>
                <Input
                 
                  type="text"
                  value={licenseNo}
                  onChange={(e) =>
                    handleMaxChars(
                      20,
                      "License No.",
                      e.target.value,
                      setLicenseNo
                    )
                  }
                />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Driver's Phone No.</FormLabel>
                <Input
                 
                  type="number"
                  value={driverPhone}
                  onChange={(e) =>
                    handleMaxChars(
                      10,
                      "Driver's Phone No.",
                      e.target.value,
                      setDriverPhone
                    )
                  }
                />
              </FormControl>
            </div> */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <FormControl isRequired={true}>
                <FormLabel>Price Type</FormLabel>
                <select
                  className="border rounded-md px-2 py-[9px] w-[18.5rem]"
                  required
                  value={priceType}
                  onChange={(e) => setPriceType(e.target.value)}
                >
                  <option>Fixed</option>
                  <option>Per Unit</option>
                </select>
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Price</FormLabel>
                <Input
                  isRequired={true}
                  type="number"
                  value={priceTotal}
                  onChange={(e) =>{
                    handleMaxChars(10, "Price", e.target.value, setPriceTotal)
                  }}
                />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Advance</FormLabel>
                <Input
                  isRequired={true}
                  type="number"
                  value={advancePaid}
                  onChange={(e) =>{
                    handleMaxChars(10, "Price", e.target.value, setAdvancePaid)
                  }}
                />
              </FormControl>
              {/* <FormControl isRequired={true}>
                <FormLabel>Balance</FormLabel>
                <Input
                  isRequired={true}
                  type="number"
                  value={balancePaid}
                  onChange={(e) =>{
                    handleMaxChars(10, "Price", e.target.value, setBalancePaid);
                  }}
                />
              </FormControl> */}
              <FormControl>
                <FormLabel>GST No.</FormLabel>
                <Input
                  type="text"
                  value={gstNo}
                  onChange={(e) =>
                    handleMaxChars(15, "GST No.", e.target.value, setGstNo)
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>St. Ch.</FormLabel>
                <Input
                  type="number"
                  value={stCh}
                  onChange={(e) =>
                    handleMaxChars(10, "St. Ch.", e.target.value, setStCh)
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Del. Ch.</FormLabel>
                <Input
                  type="number"
                  value={delCh}
                  onChange={(e) =>
                    handleMaxChars(15, "Del. Ch.", e.target.value, setDelCh)
                  }
                />
              </FormControl>
            </div>
            <div className="mt-5 mb-3">
              <Button
                isLoading={creatingBill}
                type="submit"
                width={"100%"}
                rightIcon={<LiaFileInvoiceSolid size={25} />}
                colorScheme="blue"
                className="disabled:cursor-not-allowed"
              >
                Create Bill
              </Button>
            </div>
          </form>
        </div>
    </div>
  );
};

export default CreateBill;
