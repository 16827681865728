import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CiReceipt } from "react-icons/ci";
import { TbReceiptRupee } from "react-icons/tb";
import { useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import Loader from "../components/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCookies } from "react-cookie";

const EditBill = () => {
  const toast = useToast();
  const [billId, setBillId] = useState();
  const [date, setDate] = useState();
  const [priceType, setPriceType] = useState("Fixed");
  const [priceTotal, setPriceTotal] = useState();
  const [gstNo, setGstNo] = useState();
  const [stCh, setStCh] = useState();
  const [delCh, setDelCh] = useState();
  const [advancePaid, setAdvancePaid] = useState();
  const [balancePaid, setBalancePaid] = useState();
  const [billNo, setBillNo] = useState();

  const [fetchingBill, setFetchingBill] = useState(false);
  const [updatingBill, setUpdatingBill] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies();

  const handleMaxChars = (maxChars, field, value, setFunc) => {
    if (value.length > maxChars) {
      toast({
        status: "error",
        description: `${field} field can contain max ${maxChars} characters`,
        position: "top",
      });
      return;
    }
    setFunc(value);
  };

  const fetchBillDetails = async (billId) => {
    try {
      setFetchingBill(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + `bill/get?billId=${billId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookies?.token}`,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      setBillNo(data?.bill_no);
      setGstNo(data?.gst_no);
      setDelCh(data?.del_ch);
      setStCh(data?.st_ch);
      setPriceType(data?.price_type);
      setPriceTotal(data?.total);
      setAdvancePaid(data?.advance);
      setBalancePaid(data?.balance);
      if (data?.date) {
        setDate(new Date(data?.date).toISOString().substring(0, 10));
      }
    } catch (err) {
      toast({
        position: "top",
        status: "error",
        description: err.message,
      });
    } finally {
      setFetchingBill(false);
    }
  };

  const editBillHandler = async (e) => {
    e.preventDefault();

    const body = {
      bill_no: billNo,
      bill_id: billId,
      date: date,
      price_type: priceType,
      total: priceTotal,
      gst_no: gstNo,
      st_ch: stCh,
      del_ch: delCh,
      advance: advancePaid,
      balance: balancePaid
    };

    try {
      setUpdatingBill(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + "bill/edit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies?.token}`,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }

      toast({
        status: "success",
        position: "top",
        description: data.message,
      });

      navigate("/dashboard/all-bill");
    } catch (err) {
      toast({
        position: "top",
        status: "error",
        description: err.message,
      });
    } finally {
      setUpdatingBill(false);
    }
  };

  useEffect(() => {
    if (location?.state?.billId) {
      setBillId(location?.state?.billId);
      fetchBillDetails(location?.state?.billId);
    }
  }, []);

  return (
    <div className="py-5 px-5 h-[inherit] overflow-auto">
      <h1 className="text-2xl md:text-4xl mt-5 mb-6 font-bold flex gap-2">
        <IoMdArrowRoundBack onClick={() => navigate("/dashboard/all-bill")} />
        Edit Bill
      </h1>

      {fetchingBill && <Loader />}
      <div className="mt-8">
        {!fetchingBill && (
          <form onSubmit={editBillHandler}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <FormControl>
                <FormLabel>Bill No.</FormLabel>
                <Input
                  value={billNo}
                  onChange={(e) => setBillNo(e.target.value)}
                  type="number"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Date</FormLabel>
                <Input
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Price Type</FormLabel>
                <select
                  className="border rounded-md px-2 py-[9px] w-[18rem]"
                  required
                  value={priceType}
                  onChange={(e) => setPriceType(e.target.value)}
                >
                  <option>Fixed</option>
                  <option>Per Unit</option>
                </select>
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Price</FormLabel>
                <Input
                  isRequired={true}
                  type="number"
                  value={priceTotal}
                  onChange={(e) =>{
                    if(priceTotal && advancePaid){
                      setBalancePaid(priceTotal-advancePaid);
                    }
                    handleMaxChars(10, "Price", e.target.value, setPriceTotal)
                  }}
                />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Advance</FormLabel>
                <Input
                  isRequired={true}
                  type="number"
                  value={advancePaid}
                  onChange={(e) =>{
                    if(priceTotal && advancePaid){
                      setBalancePaid(priceTotal-advancePaid);
                    }
                    handleMaxChars(10, "Advance", e.target.value, setAdvancePaid)
                  }}
                />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Balance</FormLabel>
                <Input
                  isRequired={true}
                  type="number"
                  value={balancePaid}
                  disabled
                />
              </FormControl>
              {/* <FormControl>
                <FormLabel>GST No.</FormLabel>
                <Input
                  type="text"
                  value={gstNo}
                  onChange={(e) =>
                    handleMaxChars(15, "GST No.", e.target.value, setGstNo)
                  }
                />
              </FormControl> */}
              <FormControl>
                <FormLabel>St. Ch.</FormLabel>
                <Input
                  type="number"
                  value={stCh}
                  onChange={(e) =>
                    handleMaxChars(10, "St. Ch.", e.target.value, setStCh)
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Del. Ch.</FormLabel>
                <Input
                  type="number"
                  value={delCh}
                  onChange={(e) =>
                    handleMaxChars(15, "Del. Ch.", e.target.value, setDelCh)
                  }
                />
              </FormControl>
            </div>
            <div className="mt-5 mb-3">
              <Button
                isLoading={updatingBill}
                type="submit"
                width={"100%"}
                rightIcon={<LiaFileInvoiceSolid size={25} />}
                colorScheme="blue"
                className="disabled:cursor-not-allowed"
              >
                Edit Bill
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditBill;
