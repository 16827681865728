import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { CiReceipt } from "react-icons/ci";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCookies } from "react-cookie";

const EditBilty = () => {
  const toast = useToast();
  const location = useLocation();
  const [biltyId, setBiltyId] = useState();
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [branch, setBranch] = useState("");
  // const [driverName, setDriverName] = useState("");
  const [truckNo, setTruckNo] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [consigneeNameAddress, setConsigneeNameAddress] = useState("");
  const [consignerNameAddress, setConsignerNameAddress] = useState("");
  const [quantity, setQuantity] = useState();
  const [description, setDescription] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [actualWeight, setActualWeight] = useState("");
  const [chargedWeight, setChargedWeight] = useState("");
  const [fright, setFright] = useState("");
  const [hamali, setHamali] = useState("");
  const [surCh, setSurCh] = useState("");
  const [stCh, setStCh] = useState("");
  const [aoc, setAoc] = useState("");
  const [total, setTotal] = useState("");

  const [fetchingBilty, setFetchingBilty] = useState(false);
  const [updatingBilty, setUpdatingBilty] = useState(false);

  const navigate = useNavigate();
  const [cookies] = useCookies();

  const editBiltyHandler = async (e) => {
    e.preventDefault();

    const body = {
      _id: location?.state?.biltyId,
      date: date,
      branch: branch,
      // driver_name: driverName,
      truck_no: truckNo,
      from: from,
      to: to,
      consignee_name_address: consigneeNameAddress,
      consigner_name_address: consignerNameAddress,
      quantity: quantity,
      description: description,
      invoice_no: invoiceNo,
      actual_weight: actualWeight,
      charged_weight: chargedWeight,
      fright: fright,
      hamali: hamali,
      sur_ch: surCh,
      st_ch: stCh,
      aoc: aoc,
      total: total,
    };

    try {
      setUpdatingBilty(true);
      const base_url = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(base_url + "bilty/edit", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${cookies?.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...body }),
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }

      toast({
        status: "success",
        description: data?.message || "Bilty has been created successfully",
        position: "top",
      });
      navigate("/dashboard/");
      fetchBiltyDetails(biltyId);
    } catch (err) {
      toast({
        status: "error",
        description: err?.message || "Something went wrong",
        position: "top",
      });
    } finally {
      setUpdatingBilty(false);
    }
  };

  const fetchBiltyDetails = async (biltyId) => {
    try {
      setFetchingBilty(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + `bilty/get?_id=${biltyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies?.token}`,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      setDate(new Date(data?.date).toISOString().substring(0, 10));
      setBranch(data?.branch);
      setFrom(data?.from);
      setTo(data?.to);
      // setDriverName(data?.driver_name);
      setTruckNo(data?.truck_no);
      setConsignerNameAddress(data?.consigner_name_address);
      setConsigneeNameAddress(data?.consignee_name_address);
      setQuantity(data?.quantity);
      setDescription(data?.description);
      setInvoiceNo(data?.invoice_no);
      setActualWeight(data?.actual_weight);
      setChargedWeight(data?.charged_weight);
      setFright(data?.fright);
      setHamali(data?.hamali);
      setSurCh(data?.sur_ch);
      setStCh(data?.st_ch);
      setAoc(data?.aoc);
      setTotal(data?.total);
    } catch (error) {
      toast({
        status: "error",
        description: error.message,
        position: "top",
      });
    } finally {
      setFetchingBilty(false);
    }
  };

  const handleMaxChars = (maxChars, field, value, setFunc) => {
    if (value.length > maxChars) {
      toast({
        status: "error",
        description: `${field} field can contain max ${maxChars} characters`,
        position: "top",
      });
      return;
    }
    setFunc(value);
  };

  useEffect(() => {
    if (location?.state?.biltyId) {
      setBiltyId(location?.state?.biltyId);
      fetchBiltyDetails(location?.state?.biltyId);
    }
  }, []);

  return (
    <div className="py-5 px-5 h-[inherit] overflow-auto">
      <h1 className="text-4xl mt-5 mb-10 font-bold flex gap-2">
        <IoMdArrowRoundBack onClick={() => navigate("/dashboard/")} />
        Edit Bilty
      </h1>

      {fetchingBilty && <Loader />}
      {!fetchingBilty && (
        <form onSubmit={editBiltyHandler}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 my-2">
            <FormControl>
              <FormLabel>Date</FormLabel>
              <Input
                value={date}
                onChange={(e) => setDate(e.target.value)}
                type="date"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Input
                value={branch}
                onChange={(e) =>
                  handleMaxChars(18, "Branch", e.target.value, setBranch)
                }
                type="text"
              />
            </FormControl>
            {/* <FormControl>
              <FormLabel>Driver Name</FormLabel>
              <Input
                value={driverName}
                onChange={(e) =>
                  handleMaxChars(
                    18,
                    "Driver Name",
                    e.target.value,
                    setDriverName
                  )
                }
                type="text"
              />
            </FormControl> */}
            <FormControl>
              <FormLabel>Truck No.</FormLabel>
              <Input
                value={truckNo}
                onChange={(e) =>
                  handleMaxChars(10, "Truck No.", e.target.value, setTruckNo)
                }
                type="text"
              />
            </FormControl>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-2">
            <FormControl>
              <FormLabel>From</FormLabel>
              <Input
                value={from}
                onChange={(e) =>
                  handleMaxChars(18, "From", e.target.value, setFrom)
                }
                type="text"
              />
            </FormControl>
            <FormControl>
              <FormLabel>To</FormLabel>
              <Input
                value={to}
                onChange={(e) =>
                  handleMaxChars(18, "To", e.target.value, setTo)
                }
                type="text"
              />
            </FormControl>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-2">
            <FormControl>
              <FormLabel>Consigner Name and Address</FormLabel>
              <Textarea
                value={consignerNameAddress}
                onChange={(e) =>
                  handleMaxChars(
                    100,
                    "Consigner Name and Address",
                    e.target.value,
                    setConsignerNameAddress
                  )
                }
                type="text"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Consignee Name and Address</FormLabel>
              <Textarea
                value={consigneeNameAddress}
                onChange={(e) =>
                  handleMaxChars(
                    100,
                    "Consignee Name and Address",
                    e.target.value,
                    setConsigneeNameAddress
                  )
                }
              />
            </FormControl>
          </div>

          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 my-2">
            <FormControl>
              <FormLabel>Quantity</FormLabel>
              <Input
                value={quantity}
                onChange={(e) => {
                  handleMaxChars(15, "Quantity", e.target.value, setQuantity);
                }}
                type="text"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) =>
                  handleMaxChars(
                    100,
                    "Description",
                    e.target.value,
                    setDescription
                  )
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Invoice No.</FormLabel>
              <Input
                value={invoiceNo}
                onChange={(e) =>
                  handleMaxChars(
                    15,
                    "Invoice No.",
                    e.target.value,
                    setInvoiceNo
                  )
                }
                type="text"
              />
            </FormControl>
          </div>
          <p className="font-semibold my-2">Weight (In Tonne)</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
            <FormControl>
              <FormLabel className="text-sm">Actual</FormLabel>
              <Input
                value={actualWeight}
                onChange={(e) => {
                  handleMaxChars(
                    15,
                    "Actual weight",
                    e.target.value,
                    setActualWeight
                  );
                }}
                type="text"
              />
            </FormControl>
            <FormControl>
              <FormLabel className="text-sm">Charged</FormLabel>
              <Input
                value={chargedWeight}
                onChange={(e) => {
                  handleMaxChars(
                    15,
                    "Charged weight",
                    e.target.value,
                    setChargedWeight
                  );
                }}
                type="text"
              />
            </FormControl>
          </div>

          <div className="flex flex-col w-[20rem] items-center space-y-1 my-2">
            <div className="flex w-full justify-start mb-2 font-semibold">
              <div className="w-[7rem]">Quantity</div>
              <div className="">Amount to Pay/Paid/Due</div>
            </div>
            <FormControl className="flex">
              <FormLabel className="text-sm w-[10rem]">Fright</FormLabel>
              <Input
                value={fright}
                onChange={(e) =>
                  handleMaxChars(10, "Fright", e.target.value, setFright)
                }
                type="text"
              />
            </FormControl>
            <FormControl className="flex">
              <FormLabel className="text-sm w-[10rem]">Hamali</FormLabel>
              <Input
                value={hamali}
                onChange={(e) =>
                  handleMaxChars(10, "Hamali", e.target.value, setHamali)
                }
                type="text"
              />
            </FormControl>
            <FormControl className="flex">
              <FormLabel className="text-sm w-[10rem]">Sur. Ch.</FormLabel>
              <Input
                value={surCh}
                onChange={(e) =>
                  handleMaxChars(10, "Sur. Ch.", e.target.value, setSurCh)
                }
                type="text"
              />
            </FormControl>
            <FormControl className="flex">
              <FormLabel className="text-sm w-[10rem]">St. Ch.</FormLabel>
              <Input
                value={stCh}
                onChange={(e) =>
                  handleMaxChars(10, "St. Ch.", e.target.value, setStCh)
                }
                type="text"
              />
            </FormControl>
            <FormControl className="flex">
              <FormLabel className="text-sm w-[10rem]">A.O.C.</FormLabel>
              <Input
                value={aoc}
                onChange={(e) =>
                  handleMaxChars(10, "St. Ch.", e.target.value, setAoc)
                }
                type="text"
              />
            </FormControl>
            <FormControl className="flex">
              <FormLabel className="text-s w-[10rem]">Total</FormLabel>
              <Input
                value={total}
                onChange={(e) =>
                  handleMaxChars(10, "Total", e.target.value, setTotal)
                }
                type="text"
              />
            </FormControl>
          </div>

          <div className="mt-5 mb-3">
            <Button
              isLoading={updatingBilty}
              type="submit"
              width={"100%"}
              rightIcon={<CiReceipt size={25} />}
              colorScheme="blue"
            >
              Edit Bilty
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditBilty;
