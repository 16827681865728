import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CiReceipt } from "react-icons/ci";
import { TbReceiptRupee } from "react-icons/tb";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCookies } from "react-cookie";

const CreateChallan = () => {
  const toast = useToast();
  const [findBiltyNo, setFindBiltyNo] = useState("");
  const [challanNo, setChallanNo] = useState("");
  const [biltyNo, setBiltyNo] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [lorryNo, setLorryNo] = useState("");
  const [totalPackages, setTotalPackages] = useState();
  const [weight, setWeight] = useState();
  const [freight, setFright] = useState("fixed");
  const [totalFreight, setTotalFright] = useState();
  const [advancePaid, setAdvancePaid] = useState();
  const [balancePaid, setBalancePaid] = useState();
  const [toBePaidAt, setToBePaidAt] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverPhone, setDriverPhone] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");
  const [engineNo, setEngineNo] = useState("");
  const [chessisNo, setChessisNo] = useState("");
  const [panCardNo, setPanCardNo] = useState("");
  const [agentBrokerNameAndAddress, setAgentBrokerNameAndAddress] =
    useState("");
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [contents, setContents] = useState("");
  const [destination, setDestination] = useState("");
  const [bookingStation, setBookingStation] = useState("");
  const [licenseNo, setLicenseNo] = useState();
  const [findingBilty, setFindingBilty] = useState(false);
  const [creatingChallan, setCreatingChallan] = useState(false);
  const navigate = useNavigate();
  const [cookies] = useCookies();

  const handleMaxChars = (maxChars, field, value, setFunc) => {
    if (value.length > maxChars) {
      toast({
        status: "error",
        description: `${field} field can contain max ${maxChars} characters`,
        position: "top",
      });
      return;
    }
    setFunc(value);
  };

  // const fetchBiltyDetails = async (e) => {
  //   e.preventDefault();

  //   try {
  //     setFindingBilty(true);
  //     const baseUrl = process.env.REACT_APP_BACKEND_URL;
  //     const response = await fetch(baseUrl + `bilty/get?bilty_no=${findBiltyNo}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${cookies?.token}`,
  //       },
  //     });
  //     const data = await response.json();
  //     if (!data.success) {
  //       throw new Error(data.message);
  //     }
  //     setFrom(data.from);
  //     setTo(data.to);
  //     setDriverName(data.driver_name);
  //     setLorryNo(data.truck_no);
  //     setBookingStation(data.consigner_address);
  //     setDestination(data.to);
  //     setBiltyNo(findBiltyNo);
  //   } catch (error) {
  //     toast({
  //       status: "error",
  //       description: error.message,
  //       position: "top",
  //     });
  //   } finally {
  //     setFindingBilty(false);
  //   }
  // };

  const createChallan = async (e) => {
    e.preventDefault();

    const body = {
      challan_no: challanNo,
      bilty_no: biltyNo,
      from: from,
      to: to,
      lorry_no: lorryNo,
      date: date,
      contents: contents,
      destination: destination,
      booking_station: bookingStation,
      total_packages: totalPackages,
      weight: weight,
      freight: freight,
      total_freight: totalFreight,
      advance_paid: advancePaid,
      balance_paid: balancePaid,
      to_be_paid_at: toBePaidAt,
      driver_name: driverName,
      driver_phone: driverPhone,
      license_no: licenseNo,
      owner_name: ownerName,
      owner_address: ownerAddress,
      engine_no: engineNo,
      chessis_no: chessisNo,
      pan_card_no: panCardNo,
      agent_broker_name_address: agentBrokerNameAndAddress,
    };

    try {
      setCreatingChallan(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + "challan/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies?.token}`,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }

      toast({
        status: "success",
        position: "top",
        description: data.message,
      });
      navigate("/dashboard/all-challan");
    } catch (err) {
      toast({
        position: "top",
        status: "error",
        description: err.message,
      });
    } finally {
      setCreatingChallan(false);
    }
  };

  return (
    <div className="py-5 px-5 h-[inherit] overflow-auto">
      <h1 className="text-2xl md:text-4xl mt-5 mb-6 font-bold flex gap-2">
        <IoMdArrowRoundBack onClick={() => navigate("/dashboard/")} />
        Create Challan
      </h1>

      {/* <div>
        <form onSubmit={fetchBiltyDetails}>
          <FormControl isRequired={true}>
            <FormLabel isRequired={true}>Bilty No.</FormLabel>
            <Input
              required
              type="number"
              value={findBiltyNo}
              onChange={(e) => setFindBiltyNo(e.target.value)}
            />
          </FormControl>
          <div className="mt-5 mb-3">
            <Button
              isLoading={findingBilty}
              type="submit"
              width={"100%"}
              rightIcon={<CiReceipt size={25} />}
              colorScheme="blue"
            >
              Find Bilty
            </Button>
          </div>
        </form>
      </div> */}

      <div className="mt-8">
        <form onSubmit={createChallan}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
            <FormControl isRequired={true}>
              <FormLabel>Challan No.</FormLabel>
              <Input
                type="text"
                value={challanNo}
                onChange={(e) => setChallanNo(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Bilty No.</FormLabel>
              <Input
                type="number"
                value={biltyNo}
                onChange={(e) => setBiltyNo(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>From</FormLabel>
              <Input
                type="text"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>To</FormLabel>
              <Input
                type="text"
                value={to}
                onChange={(e) => setTo(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Lorry No.</FormLabel>
              <Input
                type="text"
                value={lorryNo}
                onChange={(e) =>
                  handleMaxChars(10, "Lorry No.", e.target.value, setLorryNo)
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Date</FormLabel>
              <Input
                value={date}
                onChange={(e) => setDate(e.target.value)}
                type="date"
                // min={new Date().toISOString().substring(0, 10)}
              />
            </FormControl>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
            <FormControl isRequired={true}>
              <FormLabel>Contents</FormLabel>
              <Input
                type="text"
                value={contents}
                onChange={(e) =>
                  handleMaxChars(20, "Contents", e.target.value, setContents)
                }
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Destination</FormLabel>
              <Input
                type="text"
                value={destination}
                onChange={(e) =>
                  handleMaxChars(
                    12,
                    "Destination",
                    e.target.value,
                    setDestination
                  )
                }
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Booking Station</FormLabel>
              <Input
                type="text"
                value={bookingStation}
                onChange={(e) =>
                  handleMaxChars(
                    50,
                    "Booking Station",
                    e.target.value,
                    setBookingStation
                  )
                }
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Total Packages</FormLabel>
              <Input
                type="text"
                value={totalPackages}
                onChange={(e) =>
                  handleMaxChars(
                    20,
                    "Total Packages",
                    e.target.value,
                    setTotalPackages
                  )
                }
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Weight</FormLabel>
              <Input
                type="text"
                value={weight}
                onChange={(e) =>
                  handleMaxChars(10, "Weight", e.target.value, setWeight)
                }
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Freight</FormLabel>
              <select
                className="border rounded-md px-2 py-[9px] w-[18.5rem]"
                required
                value={freight}
                onChange={(e) => setFright(e.target.value)}
              >
                <option value="fixed">Fixed</option>
                <option value="per unit">Per Unit</option>
              </select>
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Total Freight (Rs)</FormLabel>
              <Input
                type="text"
                value={totalFreight}
                onChange={(e) => {
                  handleMaxChars(
                    10,
                    "Total Freight (Rs)",
                    e.target.value,
                    setTotalFright
                  );
                }}
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Advance Paid (Rs)</FormLabel>
              <Input
                type="number"
                value={advancePaid}
                onChange={(e) => {
                  handleMaxChars(
                    10,
                    "Advance Paid (Rs)o.",
                    e.target.value,
                    setAdvancePaid
                  );
                }}
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Balance Paid (Rs)</FormLabel>
              <Input
                type="number"
                value={balancePaid}
                onChange={(e) =>
                  handleMaxChars(
                    10,
                    "Balance Paid (Rs)",
                    e.target.value,
                    setBalancePaid
                  )
                }
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>To Be Paid At</FormLabel>
              <Input
                type="text"
                value={toBePaidAt}
                onChange={(e) =>
                  handleMaxChars(
                    10,
                    "To Be Paid At",
                    e.target.value,
                    setToBePaidAt
                  )
                }
              />
            </FormControl>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
            <FormControl>
              <FormLabel>Driver's Name</FormLabel>
              <Input
                onChange={(e) => setDriverName(e.target.value)}
                type="text"
                value={driverName}
              />
            </FormControl>
            <FormControl>
              <FormLabel>License No.</FormLabel>
              <Input
                type="text"
                value={licenseNo}
                onChange={(e) =>
                  handleMaxChars(
                    20,
                    "License No.",
                    e.target.value,
                    setLicenseNo
                  )
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Driver's Phone No.</FormLabel>
              <Input
                type="number"
                value={driverPhone}
                onChange={(e) =>
                  handleMaxChars(
                    10,
                    "Driver's Phone No.",
                    e.target.value,
                    setDriverPhone
                  )
                }
              />
            </FormControl>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-1 mt-2">
            <FormControl>
              <FormLabel>Owner's Name</FormLabel>
              <Input
                type="text"
                value={ownerName}
                onChange={(e) =>
                  handleMaxChars(
                    18,
                    "Owner's Name",
                    e.target.value,
                    setOwnerName
                  )
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Owner's Address</FormLabel>
              <Input
                type="text"
                value={ownerAddress}
                onChange={(e) =>
                  handleMaxChars(
                    50,
                    "Owner's Address",
                    e.target.value,
                    setOwnerAddress
                  )
                }
              />
            </FormControl>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-1 mt-2">
            <FormControl>
              <FormLabel>Engine No.</FormLabel>
              <Input
                type="text"
                value={engineNo}
                onChange={(e) =>
                  handleMaxChars(17, "Engine No.", e.target.value, setEngineNo)
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Chessis No.</FormLabel>
              <Input
                type="text"
                value={chessisNo}
                onChange={(e) =>
                  handleMaxChars(
                    17,
                    "Chessis No.",
                    e.target.value,
                    setChessisNo
                  )
                }
              />
            </FormControl>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-1 mt-2">
            <FormControl>
              <FormLabel>PAN Card No.</FormLabel>
              <Input
                type="text"
                value={panCardNo}
                onChange={(e) =>
                  handleMaxChars(
                    25,
                    "PAN Card No.",
                    e.target.value,
                    setPanCardNo
                  )
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Agent's/Broker's Name & Address</FormLabel>
              <Textarea
                value={agentBrokerNameAndAddress}
                onChange={(e) =>
                  handleMaxChars(
                    40,
                    "Agent's/Broker's Name & Address",
                    e.target.value,
                    setAgentBrokerNameAndAddress
                  )
                }
              />
            </FormControl>
          </div>
          <div className="mt-5 mb-3">
            <Button
              isLoading={creatingChallan}
              type="submit"
              width={"100%"}
              rightIcon={<TbReceiptRupee size={25} />}
              colorScheme="blue"
            >
              Create Challan
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateChallan;
