import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CiReceipt } from "react-icons/ci";
import { TbReceiptRupee } from "react-icons/tb";
import { useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCookies } from "react-cookie";

const EditChallan = () => {
  const toast = useToast();
  const location = useLocation();
  const [challanId, setChallanId] = useState();
  const [biltyNo, setBiltyNo] = useState("");
  const [challanNo, setChallanNo] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [lorryNo, setLorryNo] = useState("");
  const [totalPackages, setTotalPackages] = useState();
  const [weight, setWeight] = useState();
  const [freight, setFright] = useState();
  const [totalFreight, setTotalFright] = useState();
  const [advancePaid, setAdvancePaid] = useState();
  const [balancePaid, setBalancePaid] = useState();
  const [toBePaidAt, setToBePaidAt] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverPhone, setDriverPhone] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");
  const [engineNo, setEngineNo] = useState("");
  const [chessisNo, setChessisNo] = useState("");
  const [panCardNo, setPanCardNo] = useState("");
  const [agentBrokerNameAndAddress, setAgentBrokerNameAndAddress] =
    useState("");
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [contents, setContents] = useState("");
  const [destination, setDestination] = useState("");
  const [bookingStation, setBookingStation] = useState("");

  const [fetchingChallan, setFetchingChallan] = useState(false);
  const [updatingChallan, setUpdatingChallan] = useState(false);

  const navigate = useNavigate();
  const [cookies] = useCookies();

  const handleMaxChars = (maxChars, field, value, setFunc) => {
    if (value.length > maxChars) {
      toast({
        status: "error",
        description: `${field} field can contain max ${maxChars} characters`,
        position: "top",
      });
      return;
    }
    setFunc(value);
  };

  const fetchChallanDetails = async (challanId) => {
    try {
      setFetchingChallan(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + `challan/get?id=${challanId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookies?.token}`,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }

      setChallanNo(data?.challan?.challan_no);
      setBiltyNo(data?.challan?.bilty_no);
      setFrom(data?.challan?.from);
      setTo(data?.challan?.to);
      setLorryNo(data?.challan?.lorry_no);
      setTotalPackages(data?.challan?.total_packages);
      setWeight(data?.challan?.weight);
      setFright(data?.challan?.freight);
      setFright(data?.challan?.freight);
      setTotalFright(data?.challan?.total_freight);
      setAdvancePaid(data?.challan?.advance_paid);
      setBalancePaid(data?.challan?.balance_paid);
      setToBePaidAt(data?.challan?.to_be_paid_at);
      setDriverName(data?.challan?.driver_name);
      setDriverPhone(data?.challan?.driver_phone);
      setOwnerName(data?.challan?.owner_name);
      setOwnerAddress(data?.challan?.owner_address);
      setEngineNo(data?.challan?.engine_no);
      setChessisNo(data?.challan?.chessis_no);
      setPanCardNo(data?.challan?.pan_card_no);
      setAgentBrokerNameAndAddress(data?.challan?.agent_broker_name_address);
      setDate(data?.challan?.date);
      setContents(data?.challan?.contents);
      setDestination(data?.challan?.destination);
      setBookingStation(data?.challan?.booking_station);
    } catch (error) {
      toast({
        status: "error",
        description: error.message,
        position: "top",
      });
    } finally {
      setFetchingChallan(false);
    }
  };

  const EditChallan = async (e) => {
    e.preventDefault();

    const body = {
      _id: challanId,
      challan_no: challanNo,
      from: from,
      to: to,
      lorry_no: lorryNo,
      date: date,
      contents: contents,
      destination: destination,
      booking_station: bookingStation,
      total_packages: totalPackages,
      weight: weight,
      freight: freight,
      total_freight: totalFreight,
      advance_paid: advancePaid,
      balance_paid: balancePaid,
      to_be_paid_at: toBePaidAt,
      driver_name: driverName,
      driver_phone: driverPhone,
      owner_name: ownerName,
      owner_address: ownerAddress,
      engine_no: engineNo,
      chessis_no: chessisNo,
      pan_card_no: panCardNo,
      agent_broker_name_address: agentBrokerNameAndAddress,
    };

    try {
      setUpdatingChallan(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + "challan/edit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies?.token}`,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }

      toast({
        status: "success",
        position: "top",
        description: data.message,
      });
      navigate("/dashboard/all-challan");
      fetchChallanDetails(challanId);
    } catch (err) {
      toast({
        position: "top",
        status: "error",
        description: err.message,
      });
    } finally {
      setUpdatingChallan(false);
    }
  };

  useEffect(() => {
    if (location?.state?.challanId) {
      setChallanId(location?.state?.challanId);
      fetchChallanDetails(location?.state?.challanId);
    }
  }, []);

  return (
    <div className="py-5 px-5 h-[inherit] overflow-auto">
      <h1 className="text-2xl md:text-4xl mt-5 mb-6 font-bold flex gap-2">
        <IoMdArrowRoundBack
          onClick={() => navigate("/dashboard/all-challan")}
        />
        Edit Challan
      </h1>

      {fetchingChallan && <Loader />}
      {!fetchingChallan && (
        <div className="mt-8">
          <form onSubmit={EditChallan}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <FormControl isRequired={true}>
                <FormLabel>Challan No.</FormLabel>
                <Input
                  type="text"
                  value={challanNo}
                  onChange={(e) => setChallanNo(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>From</FormLabel>
                <Input type="text" value={from} />
              </FormControl>
              <FormControl>
                <FormLabel>To</FormLabel>
                <Input type="text" value={to} />
              </FormControl>
              <FormControl>
                <FormLabel>Lorry No.</FormLabel>
                <Input
                  type="text"
                  value={lorryNo}
                  onChange={(e) =>
                    handleMaxChars(10, "Lorry No.", e.target.value, setLorryNo)
                  }
                />
              </FormControl>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <FormControl>
                <FormLabel>Date</FormLabel>
                <Input
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                  min={new Date().toISOString().substring(0, 10)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Contents</FormLabel>
                <Input
                  type="text"
                  value={contents}
                  onChange={(e) =>
                    handleMaxChars(20, "Contents", e.target.value, setContents)
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Destination</FormLabel>
                <Input
                  type="text"
                  value={destination}
                  onChange={(e) =>
                    handleMaxChars(
                      12,
                      "Destination",
                      e.target.value,
                      setDestination
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Booking Station</FormLabel>
                <Input
                  type="text"
                  value={bookingStation}
                  onChange={(e) =>
                    handleMaxChars(
                      18,
                      "Booking Station",
                      e.target.value,
                      setBookingStation
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Total Packages</FormLabel>
                <Input
                  type="text"
                  value={totalPackages}
                  onChange={(e) =>
                    handleMaxChars(
                      10,
                      "Total Packages",
                      e.target.value,
                      setTotalPackages
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Weight</FormLabel>
                <Input
                  type="text"
                  value={weight}
                  onChange={(e) =>
                    handleMaxChars(10, "Weight", e.target.value, setWeight)
                  }
                />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Freight</FormLabel>
                <select
                  className="border rounded-md px-2 py-[9px] w-[18.5rem]"
                  required
                  value={freight}
                  onChange={(e) => setFright(e.target.value)}
                >
                  <option>Fixed</option>
                  <option>Per Unit</option>
                </select>
              </FormControl>
              <FormControl>
                <FormLabel>Total Freight (Rs)</FormLabel>
                <Input
                  type="text"
                  value={totalFreight}
                  onChange={(e) => {
                    if (advancePaid) {
                      setBalancePaid(e.target.value - advancePaid);
                    }
                    handleMaxChars(
                      10,
                      "Total Freight (Rs)",
                      e.target.value,
                      setTotalFright
                    );
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Advance Paid (Rs)</FormLabel>
                <Input
                  type="number"
                  value={advancePaid}
                  onChange={(e) => {
                    if (totalFreight) {
                      setBalancePaid(totalFreight - e.target.value);
                    }
                    handleMaxChars(
                      10,
                      "Advance Paid (Rs)o.",
                      e.target.value,
                      setAdvancePaid
                    );
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Balance Paid (Rs)</FormLabel>
                <Input
                  type="number"
                  value={balancePaid}
                  onChange={(e) =>
                    handleMaxChars(
                      10,
                      "Balance Paid (Rs)",
                      e.target.value,
                      setBalancePaid
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>To Be Paid At</FormLabel>
                <Input
                  type="text"
                  value={toBePaidAt}
                  onChange={(e) =>
                    handleMaxChars(
                      10,
                      "To Be Paid At",
                      e.target.value,
                      setToBePaidAt
                    )
                  }
                />
              </FormControl>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <FormControl>
                <FormLabel>Driver's Name</FormLabel>
                <Input type="text" value={driverName} />
              </FormControl>
              <FormControl>
                <FormLabel>Driver's Phone No.</FormLabel>
                <Input
                  type="number"
                  value={driverPhone}
                  onChange={(e) =>
                    handleMaxChars(
                      10,
                      "Driver's Phone No.",
                      e.target.value,
                      setDriverPhone
                    )
                  }
                />
              </FormControl>

              <FormControl>
                <FormLabel>Owner's Name</FormLabel>
                <Input
                  type="text"
                  value={ownerName}
                  onChange={(e) =>
                    handleMaxChars(
                      18,
                      "Owner's Name",
                      e.target.value,
                      setOwnerName
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Owner's Address</FormLabel>
                <Input
                  type="text"
                  value={ownerAddress}
                  onChange={(e) =>
                    handleMaxChars(
                      30,
                      "Owner's Address",
                      e.target.value,
                      setOwnerAddress
                    )
                  }
                />
              </FormControl>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-1 mt-2">
              <FormControl>
                <FormLabel>Engine No.</FormLabel>
                <Input
                  type="text"
                  value={engineNo}
                  onChange={(e) =>
                    handleMaxChars(
                      17,
                      "Engine No.",
                      e.target.value,
                      setEngineNo
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Chessis No.</FormLabel>
                <Input
                  type="text"
                  value={chessisNo}
                  onChange={(e) =>
                    handleMaxChars(
                      17,
                      "Chessis No.",
                      e.target.value,
                      setChessisNo
                    )
                  }
                />
              </FormControl>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-1 mt-2">
              <FormControl>
                <FormLabel>PAN Card No.</FormLabel>
                <Input
                  type="text"
                  value={panCardNo}
                  onChange={(e) =>
                    handleMaxChars(
                      25,
                      "PAN Card No.",
                      e.target.value,
                      setPanCardNo
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Agent's/Broker's Name & Address</FormLabel>
                <Textarea
                  value={agentBrokerNameAndAddress}
                  onChange={(e) =>
                    handleMaxChars(
                      40,
                      "Agent's/Broker's Name & Address",
                      e.target.value,
                      setAgentBrokerNameAndAddress
                    )
                  }
                />
              </FormControl>
            </div>
            <div className="mt-5 mb-3">
              <Button
                isLoading={updatingChallan}
                type="submit"
                width={"100%"}
                rightIcon={<TbReceiptRupee size={25} />}
                colorScheme="blue"
              >
                Edit Challan
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditChallan;
