import moment from "moment";
import { usePagination, useSortBy, useTable } from "react-table";
import { MdModeEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { IoMdDownload } from "react-icons/io";

const Table = (props) => {
  const {
    columns = [],
    data = [],
    fetchDetails = () => {},
    editHandler = () => {},
    downloadHandler = () => {},
    viewHandler = () => {},
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex },
    pageCount,
  } = useTable({ columns, data }, useSortBy, usePagination);

  return (
    <div>
      <div className="overflow-auto">
        <table
          className="text-justify w-full m-auto border-[#a5a5a5]"
          {...getTableProps()}
        >
          <thead className="">
            {headerGroups.map((hg) => {
              return (
                <tr
                  className="text-[#6a676c] border-[#3182ce] border-b"
                  {...hg.getHeaderGroupProps()}
                >
                  <th className="sticky top-0 left-0 bg-white"></th>
                  {hg.headers.map((column) => {
                    return (
                      <th
                        className="px-3 pb-3 font-medium"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {column.isSorted && (
                          <span>
                            {column.isSortedDesc ? (
                              <i class="bx bx-chevron-down"></i>
                            ) : (
                              <i class="bx bx-chevron-up"></i>
                            )}
                          </span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);

              return (
                <tr
                  onClick={() =>
                    fetchDetails !== undefined &&
                    fetchDetails(row.cells[0].value)
                  }
                  className="font-medium hover:bg-[#e4e4e4] hover:cursor-pointer"
                  {...row.getRowProps()}
                >
                  <td className="sticky top-0 left-0 bg-white flex px-2 md:px-3 py-[10px] md:py-[17px] gap-2 text-sm border-b">
                    <div
                      onClick={() => viewHandler(row.original._id)}
                      className="bg-[#3182ce] text-[#f3f3f3] rounded px-2 py-1 hover:scale-110"
                    >
                      <IoMdEye />
                    </div>
                    <div
                      onClick={() => editHandler(row.original._id)}
                      className="bg-[#3182ce] text-[#f3f3f3] rounded px-2 py-1 hover:scale-110"
                    >
                      <MdModeEdit />
                    </div>
                    <div
                      className="bg-[#3182ce] text-[#f3f3f3] rounded px-2 py-1 hover:scale-110"
                      onClick={() => downloadHandler(row.original._id)}
                    >
                      <IoMdDownload />
                    </div>
                  </td>
                  {/* <td className='flex px-3 py-[17px] gap-2 text-2xl border-b'>
                                <div onClick={()=>viewHandler(row.original._id)} className='text-[#3182ce] rounded hover:scale-110'><IoMdEye /></div>
                                <div onClick={()=>editHandler(row.original._id)} className='text-[#3182ce] rounded hover:scale-110'><MdModeEdit /></div>
                                <div className='text-[#3182ce] rounded hover:scale-110' onClick={()=>downloadHandler(row.original._id)}><IoMdDownload /></div>
                            </td> */}
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className="border-b px-3 py-2"
                        {...cell.getCellProps()}
                      >
                        {cell.column.id !== "date" && (
                          <span>
                            {cell?.value?.toString()?.substr(0, 14)}
                            {cell?.value?.length > 14 && "..."}
                          </span>
                        )}
                        {cell.column.id == "date" &&
                          moment(row.original.date).format("DD-MM-YYYY")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="w-[max-content] m-auto my-7">
        <button
          className="mt-2 bg-[#3182ce] py-1 px-5 text-white border-[1px] border-[#3182ce] rounded-3xl disabled:bg-[#b2b2b2] disabled:border-[#b2b2b2] disabled:cursor-not-allowed"
          disabled={!canPreviousPage}
          onClick={previousPage}
        >
          Prev
        </button>
        <span className="mx-3">
          {pageIndex + 1} of {pageCount}
        </span>
        <button
          className="mt-2 mr-2 bg-[#3182ce] py-1 px-5 text-white border-[1px] border-[#3182ce] rounded-3xl disabled:bg-[#b2b2b2] disabled:border-[#b2b2b2] disabled:cursor-not-allowed"
          disabled={!canNextPage}
          onClick={nextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Table;
